import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ButtonSet } from '@components/ButtonSet';
import { ModalCmp } from '@components/ModalCmp';
import { useSearchContext } from '@contexts/SearchProvider';
import {
  DraftReportFormData,
  DraftReportFormList,
  DraftReportPeriod,
  DraftReportStatus,
  REPORT_STATUS_KEY,
  ReportPage,
} from '@core/@models/DraftReportModel';
import { ApiService } from '@core/services/api.service';
import { CustomService } from '@core/services/custom.service';
import { LanguageService } from '@core/services/language.service';
import { useBrokerReport } from '@helpers/use-broker-report';
import { convertByteToMB, convertToDateString } from '@helpers/utils';
import { configAntProvider } from '@layouts/DefaultLayout';
import { MAIN, REPORT_STATUS } from '@styles/constant/color';
import { Button, DatePicker, Space, Table } from 'antd';
import { ButtonProps } from 'antd/es/button';
import locale from 'antd/lib/date-picker/locale/th_TH';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import { ReactComponent as XlsxSvg } from 'assets/icon/xlsx.svg';
import { ReactComponent as XmlSvg } from 'assets/icon/xml.svg';
import { ReactComponent as ZipSvg } from 'assets/icon/zip.svg';
import dayjs from 'dayjs';
import moment from 'moment';
import 'moment/locale/th';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';
import { finalize } from 'rxjs/operators';
import styled from 'styled-components';
import { Uploader } from './Uploader';
import { AxiosResponse } from 'axios';
import { NotificationType, useNotification } from '@helpers/use-notification';

const dateFormat = 'DD/MM/YYYY';
const monthFormat = 'MMMM';
const antLang = Object.assign({}, locale.lang, {
  monthFormat: monthFormat,
});
const localeThai = {
  ...locale,
  ...{ antLang },
};

const endPeriodYear = `${dayjs().add(5, 'year').year()}`;
const startPeriodYear = `${dayjs().subtract(3, 'year').year()}`;
export interface DraftReportFormProps {
  id: number | null;
  periodName: string;
  page: ReportPage;
  handleCancel: () => void;
}

export const DraftReportForm: React.FC<DraftReportFormProps> = ({
  page,
  periodName,
  ...props
}) => {
  const { menuId, roleId } = useSearchContext();
  const lang = LanguageService.getLanguage();
  const { t } = useTranslation(['common']);
  const { queryParam, handleQueryParam } = useSearchContext();
  const {
    renderDownloadFile,
    renderLastUpdateDate,
    fileLoading,
  } = useBrokerReport();
  const { open } = useNotification();
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [dataSource, setDataSource] = useState<DraftReportFormData>();
  const [periodDate, setPeriodDate] = useState<string>(
    dayjs().locale('en').format(dateFormat)
  );
  const filesRef = useRef<File[]>([]);
  const result = useRef<DraftReportFormData>();
  const apiService = useMemo(
    () => new ApiService(`/broker-report-group/${page.toLowerCase()}`),
    [page]
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  useEffect(() => {
    let mounted = true;
    if (!props.id || !page) return;
    if (!mounted) return;
    getDataById();
    return () => {
      mounted = false;
    };
  }, [props.id, page]);

  useEffect(() => {
    if (props.id) return;
    getDataByPeriod();
  }, [periodName]);

  const transformData = (response: DraftReportFormData) => {
    const reports = response.reports.map((val, index) => ({
      ...val,
      delete: false,
      order: index + 1,
    }));
    result.current = Object.assign({}, response, { reports });
  };

  const getDataByPeriod = () => {
    const period = periodName.substring(0, 1) as DraftReportPeriod;
    CustomService.getDataById<DraftReportFormData>(
      `broker-report-group/draft?period=${period}`
    )
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (response: DraftReportFormData) => {
          transformData(response);
          const date =
            period === DraftReportPeriod.MONTHLY
              ? dayjs().subtract(1, 'month').locale('en')
              : dayjs().locale('en');
          const periodDateStr = dayjs(date).format(dateFormat);
          const periodDateResponse = dayjs(date).format('YYYY-MM-DD');
          setPeriodDate(periodDateStr);
          result.current = Object.assign({}, result.current, {
            periodDate: periodDateResponse,
          });
          setDataSource(result.current);
        },
      });
  };

  const getDataById = () => {
    CustomService.getDataById<DraftReportFormData>(
      `broker-report-group/${page.toLowerCase()}?id=${props.id}`,
      {
        'x-menu-id': menuId,
        'x-role-id': roleId,
      }
    )
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (response: DraftReportFormData) => {
          transformData(response);
          const periodDateStr = dayjs(response.periodDate)
            .locale('en')
            .format(dateFormat);
          setPeriodDate(periodDateStr);
          setDataSource(result.current);
          getPreviewFile();
        },
      });
  };

  const getPreviewFile = async () => {
    if (result.current?.reports) {
      result.current.reports.forEach((report, index) => {
        if (report.reportId && !filesRef.current[index]) {
          CustomService.download(
            `/broker-report-group/download-file?reportId=${report.reportId}`
          )
            .pipe(finalize(() => setBtnLoading(false)))
            .subscribe({
              next: (result: AxiosResponse<Blob>) => {
                const { data, headers } = result;
                const fileName = headers['content-disposition']
                  .split(';')[1]
                  .split('=')[1]
                  .replace('"', '')
                  .replace('"', '');

                const blob = new Blob([data]);
                filesRef.current[index] = new File([blob], fileName);
              },
              error: () => {
                open({
                  type: NotificationType.ERROR,
                  description: t('common:downloadFailed'),
                  disableDate: true,
                });
              },
            });
        }
      });
    }
  };

  const renderOrder = (value: number) => <>{value}.</>;

  const renderStatus = (value: string, record: DraftReportFormList) =>
    record.status && (
      <>
        <Status status={record.status}>{value}</Status>
        {record.error && record.status !== DraftReportStatus.DELETE && (
          <ExclamationIcon onClick={() => setErrorMessage(record.error)} />
        )}
      </>
    );

  const handleUpload = (file: File, index: number) => {
    filesRef.current[index] = file;
    if (!result.current) return;
    result.current.reports[index].fileName = file.name;
  };

  const renderUploader = (_: string, record: DraftReportFormList) => {
    const uploaderRef = useRef<{ clearFileList: () => void }>();
    const { order, limitFileSize, fileTypes } = record;
    const uploadButtonProps: ButtonProps = {
      size: 'small',
      style: { fontSize: '10px' },
    };
    return (
      <UploadWrapper>
        <Uploader
          handleUpload={handleUpload}
          index={order - 1}
          maxSize={convertByteToMB(limitFileSize)}
          fileTypes={fileTypes}
          ref={uploaderRef}
          showRemoveIcon={false}
          buttonProps={uploadButtonProps}
        />
      </UploadWrapper>
    );
  };

  const draftColumns: ColumnsType<DraftReportFormList> = [
    {
      title: t('order'),
      align: 'center',
      width: '5%',
      dataIndex: 'order',
      render: renderOrder,
    },
    {
      title: t('data'),
      dataIndex: 'templateName',
    },
    {
      title: 'Template',
      width: '10%',
      align: 'center',
      dataIndex: 'templateId',
      render: (value: string) =>
        renderDownloadFile(
          XlsxSvg,
          `/broker-report-group/download-template?period=${result.current?.period}&templateId=${value}`
        ),
    },
    {
      title: 'Upload',
      dataIndex: 'templateId',
      width: '13%',
      render: renderUploader,
    },
    {
      title: t('lastUpdate'),
      dataIndex: 'lastUpdate',
      align: 'center',
      width: '15%',
      render: (_, record: DraftReportFormList) => {
        return renderLastUpdateDate(record.updateDate, record.updater);
      },
    },
    {
      title: 'Review',
      dataIndex: 'reportId',
      align: 'center',
      width: '6%',
      render: (value: string, record: DraftReportFormList) =>
        record.isReview &&
        renderDownloadFile(
          XmlSvg,
          `/broker-report-group/download-file?reportId=${value}`
        ),
    },
    {
      title: 'Digest',
      dataIndex: 'reportId',
      align: 'center',
      width: '6%',
      render: (value: string, record: DraftReportFormList) =>
        record.isDigest &&
        renderDownloadFile(
          ZipSvg,
          `/broker-report-group/download-digest?reportId=${value}`
        ),
    },
    {
      title: t('status'),
      dataIndex: 'statusName',
      width: '10%',
      align: 'center',
      render: renderStatus,
      className: 'last-column',
    },
  ];

  const getColumns = useCallback(() => {
    const raws = [...draftColumns];
    if (page === ReportPage.SENT) {
      raws.splice(3, 1);
      return raws;
    }
    return raws;
  }, [page]);

  const rowSelection: TableRowSelection<DraftReportFormList> = {
    columnTitle: t('actions.delete'),
    columnWidth: '6%',
    selectedRowKeys,
    onChange: (selectedKeys: React.Key[]) => {
      setSelectedRowKeys(selectedKeys);
    },
    onSelect: (record: DraftReportFormList, selected: boolean) => {
      if (!result.current) return;
      result.current.reports.forEach((report) => {
        if (report.reportId === record.reportId) {
          report.delete = selected;
        }
        return report;
      });
    },
    getCheckboxProps: (record: DraftReportFormList) => ({
      name: `${record.templateId}`,
      style: {
        display:
          record.reportId && record.status !== DraftReportStatus.DELETE
            ? 'inline-block'
            : 'none',
      },
    }),
  };

  const handleSave = () => {
    setBtnLoading(true);
    const formData = new FormData();

    if (filesRef.current.length > 0) {
      filesRef.current.forEach((file: File, index: number) => {
        const report = result.current?.reports[index];
        if (report) report.fileName = file.name;
      });
    } else {
      getPreviewFile();
    }

    const data = new Blob([JSON.stringify(result.current)], {
      type: 'application/json',
    });
    formData.append('=data=', data, 'data.json');
    filesRef.current.forEach((file: File) => {
      formData.append(file.name, file, file.name);
    });

    const action$ = apiService.createData<FormData, DraftReportFormData>(
      formData
    );
    action$.pipe(finalize(() => setBtnLoading(false))).subscribe({
      next: (response: DraftReportFormData) => {
        transformData(response);
        setDataSource(result.current);
        const message = getErrorMessages(response.reports);
        setErrorMessage(message.join(''));
        handleQueryParam(queryParam);
        setSelectedRowKeys([]);
      },
    });
  };

  const getErrorMessages = (
    reports: DraftReportFormList[] | null
  ): (string | null)[] => {
    if (!reports) return [];
    return reports
      .filter((v) => v.status !== DraftReportStatus.DELETE)
      .map((v, index) => {
        return (
          v.error &&
          `<div style="font-weight:bold">${index + 1}. ${
            v.templateName
          }</div><div style="margin-bottom:9px">${v.error}</div>`
        );
      })
      .filter((v) => !!v);
  };

  const buttonProps: ButtonProps = useMemo(
    () => ({
      onClick: handleSave,
      htmlType: 'button',
    }),
    [handleSave]
  );

  const handleDateChange = (dateString: string, picker?: 'month' | 'year') => {
    let periodDateStr = dateString;
    const [, month, year] = periodDate.split('/');
    if (picker === 'month') {
      const MM = `${dayjs.months().indexOf(dateString) + 1}`.padStart(2, '0');
      periodDateStr = `${year}-${MM}`;
    } else if (picker === 'year') {
      periodDateStr = `${dateString}-${month}`;
    }
    if (picker) {
      const lastDay = dayjs(periodDateStr).daysInMonth();
      periodDateStr = dayjs(`${periodDateStr}-${lastDay}`).format(dateFormat);
    }

    setPeriodDate(periodDateStr);
    if (!result.current) return;
    result.current.periodDate = convertToDateString(periodDateStr);
  };

  return (
    <>
      <Wrapper>
        <Space direction="vertical">
          <h3>
            {t('draftReport', {
              reportPeriod: result.current?.periodName,
            })}
            {result.current?.status && (
              <Space style={{ marginLeft: 3 }}>
                [
                <Status status={result.current.status}>
                  {result.current.statusName}
                </Status>
                ]
              </Space>
            )}
          </h3>
          <Space>
            <label>{t('period')}</label>
            {result.current?.period === DraftReportPeriod.DAILY ? (
              <DatePicker
                data-testid="period-date-picker"
                value={moment(periodDate, dateFormat)}
                format={dateFormat}
                style={{ width: '200px' }}
                onChange={(_: moment.Moment | null, dateString: string) =>
                  handleDateChange(dateString)
                }
                allowClear={false}
                disabled={page === ReportPage.SENT}
              />
            ) : (
              <>
                <DatePicker
                  data-testid="period-month-picker"
                  picker="month"
                  allowClear={false}
                  format={monthFormat}
                  locale={lang === 'th' ? localeThai : configAntProvider[lang]}
                  value={moment(periodDate, dateFormat)}
                  onChange={(_: moment.Moment | null, dateString: string) =>
                    handleDateChange(dateString, 'month')
                  }
                  disabled={page === ReportPage.SENT}
                />
                <DatePicker
                  data-testid="period-year-picker"
                  picker="year"
                  allowClear={false}
                  format={'YYYY'}
                  disabledDate={(d) =>
                    d.isBefore(startPeriodYear) || d.isAfter(endPeriodYear)
                  }
                  locale={configAntProvider[lang]}
                  value={moment(periodDate, dateFormat)}
                  onChange={(_: moment.Moment | null, dateString: string) =>
                    handleDateChange(dateString, 'year')
                  }
                  disabled={page === ReportPage.SENT}
                />
              </>
            )}
          </Space>
        </Space>
      </Wrapper>
      <Table<DraftReportFormList>
        data-testid="draft-report-form-table"
        pagination={{
          pageSize: 10,
          showSizeChanger: false,
          showTotal: (total) => t('totalItem', { total }),
        }}
        rowKey="templateId"
        size="small"
        className="selectable-table"
        columns={getColumns()}
        dataSource={dataSource?.reports}
        rowSelection={page === ReportPage.DRAFT ? rowSelection : undefined}
        loading={loading || fileLoading}
      />
      {page === ReportPage.DRAFT ? (
        <ButtonSet
          handleCancel={props.handleCancel}
          loading={btnLoading}
          buttonProps={buttonProps}
          disabled={btnLoading}
        />
      ) : (
        <BtnWrapper>
          <Button
            style={{ width: '80px' }}
            data-testid="close"
            type="primary"
            ghost
            htmlType="button"
            onClick={props.handleCancel}
          >
            {t('button.close')}
          </Button>
        </BtnWrapper>
      )}
      {errorMessage && (
        <ModalCmp
          visible={!!errorMessage}
          title={`${t('draftReport', {
            reportPeriod: result.current?.periodName,
          })}`}
          width={700}
        >
          <Error>[Error]</Error>
          {ReactHtmlParser(errorMessage)}
          <BtnWrapper>
            <Button
              style={{ width: '80px' }}
              data-testid="close-error-button"
              type="primary"
              ghost
              htmlType="button"
              onClick={() => setErrorMessage(undefined)}
            >
              {t('button.close')}
            </Button>
          </BtnWrapper>
        </ModalCmp>
      )}
    </>
  );
};

export const Status = styled.span<{ status: REPORT_STATUS_KEY }>`
  color: ${({ status }) => REPORT_STATUS[status]};
`;

const UploadWrapper = styled.div`
  width: 30px;
  & .anticon svg {
    width: 12px;
  }
`;

const Wrapper = styled.div`
  text-align: center;
  margin: 8px 0 40px 0;
`;

const ExclamationIcon = styled(ExclamationCircleOutlined)`
  color: ${MAIN.WARNING};
  margin-left: 2px;
  display: 'inline-block';
`;

const BtnWrapper = styled(Space)`
  direction: 'vertical';
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10px;
`;

const Error = styled.div`
  color: ${MAIN.WARNING};
  font-weight: bold;
`;
